<template>
  <div style="margin-top: 7rem; min-height: 80vh;" class="d-flex justify-content-center">
    <div class="m-5 w-50 ">
        <div class="">
          <img
            :src="FILESURL + event.fe_thumbnail"
            class="card-img-top"
            alt=""
          />
        </div>
        <div class="mt-3">
          <div class="">
            <div class="">
              <div
                class="card-title font-weight-bold"
                style="font-size: 1.8rem; color: #333"
              >
                {{ event.fe_name }}
              </div>
              <div
                class="mt-1"
                style="font-size: medium"
                v-html="event.fe_description"
              ></div>
              <div class="mt-2">
                <feather-icon icon="MapPinIcon" size="30" stroke="#333" />
                <span class="ml-1 font-weight-bold" style="font-size: 1.2rem">{{
                  event.fe_venue === null || event.fe_venue === ""
                    ? "Online"
                    : event.fe_venue
                }}</span>
                <span class="ml-1" v-if="event.fe_mode === 'online'">
                  <a
                    :href="event.fe_link"
                    style="text-decoration: underline; font-size: medium"
                    @click.stop
                    target="_blank"
                    >Join</a
                  >
                </span>
              </div>
              <div class="mt-2">
                <span class="mr-2">
                  <feather-icon icon="CalendarIcon" size="30" stroke="#333" />
                </span>
                <span class="font-weight-bold" style="font-size: larger">
                  {{ event.fe_start_date }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import CommonServices from "@/apiServices/CommonServices";
import { FILESURL } from "@/config/index";

export default {
  component: {},
  data() {
    return {
      event: {},
      FILESURL,
    };
  },
  methods: {
    async getEventById(id) {
      try {
        const payload = { fe_id: id };
        const res = await CommonServices.editEvents(payload);
        if (res.data.status) {
          this.event = res.data.data;
          console.log("Event by id", this.event);
        }
      } catch (error) {
        console.error("Error in getEventById ", error);
      }
    },
  },

  beforeMount() {
    const e_id = this.$route.query.e_id;
    if (e_id) {
      this.getEventById(e_id);
    }
  },
};
</script>
